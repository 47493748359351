.short-term-parking, .monthly-parking {
    display: flex;
}

.map-container {
    order: 1;
    flex-grow: 1;
}

.table-container {
    order: 2;
    flex-grow: 2;
}
