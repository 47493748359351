body {
  background-image: url("https://www.palmettoparking.com/images/ravenel-bridge.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5 {
  font-family: Halant, serif;
}

.App {
  text-align: center;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

.App-link {
  color: rgb(0, 50, 170);
}

.bg-override {
  background-color: rgba(170,170,170, 0.5) !important;
}

.header-outer-container {
  background: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position:relative;
  width: 100%;
  height: 100%;
}

.header-inner-container {
  justify-content: space-between;
}

.header-link-container {
}

.header-logo {
}

.header-links  {
  bottom:0;
  flex-grow: 6;
}

.main-content-background {
  min-height: 50vh;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.main-content-box {
  max-width: 1675px;
  margin: 2vh 10vw;
  background: rgba(255,255,255,0.85);
}

.main-content-body {
  margin: 15px;
  text-align: start;
  color: black;
}

.blue-text {
  color: rgb(0, 50, 170);
  margin-left: 10px;
}

.large-red-text {
  color: darkred;
  font-size: +1em;
  margin-left: 10px;
}

.disclaimer {
  font-size: smaller;
}

.flex-table {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}

.flex-table-cell {
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.8em 1.2em;
  overflow: hidden;
  list-style: none;
  border: solid 2px white;
}

.flex-table-cell-sm {
  width: 20%;
}

.flex-table-cell-md {
  width: 30%;
}

.flex-table-cell-lg {
  flex-grow: 2;
  width: 200%;
}

.flex-table-header {
  text-align: center;
  background-color: rgba(170,170,170,0.5);
}

.flex-table-row {
  display: inline-flex;
  width: 100%;
}

.flex-table-edited-row {
  display: inline-flex;
  width: 100%;
  background-color: rgba(255, 255, 170, 0.5);
}

.flex-input {
  width: inherit;
}

.droppable-row {
  width: 100%;
}

.inactive-faq {
  opacity: 33%;
}

.faq-order {
  margin-left: auto;
  order: 2;
  color: lightsteelblue;
}

.enabled {
  color: rgb(0,50,170);
  cursor: pointer;
}

.disabled {
  color: rgb(170,170,170);
  cursor: pointer;
}

.available {
  color: rgb(0, 170, 50);
}

.available:hover + .availability-tooltip {
  display: block;
}

.unavailable {
  color: darkred;
}

.unavailable:hover + .availability-tooltip {
  display: block;
  border: darkred solid 1px;
  background-color: #aa7777;
  color: darkred;
}

.availability-tooltip {
  border: rgb(0, 170, 50) solid 1px;
  background-color: #334433;
  color: rgb(0, 170, 50);
  padding: 6px;
  text-align: center;
  display: none;
  position: absolute;
}

.text-to-pay {
  color: rgb(0, 50, 170);
}

.text-to-pay:hover + .text-to-pay-tooltip {
  display: block;
}

.text-to-pay-tooltip {
  border: rgb(0, 50, 170) solid 1px;
  background-color: lightskyblue;
  color: rgb(0, 50, 170);
  padding: 6px;
  text-align: center;
  display: none;
  position: absolute;
}

.hourly-rates {
  color: rgb(0, 50, 170);
}

.hourly-rates:hover + .hourly-rates-tooltip {
  display: block;
}

.hourly-rates-tooltip {
  border: rgb(0, 50, 170) solid 1px;
  background-color: lightskyblue;
  color: rgb(0, 50, 170);
  padding: 6px;
  text-align: center;
  display: none;
  position: absolute;
}

.flat-rate {
  color: rgb(0, 50, 170);
}

.flat-rate:hover + .flat-rate-tooltip {
  display: block;
}

.flat-rate-tooltip {
  border: rgb(0, 50, 170) solid 1px;
  background-color: lightskyblue;
  color: rgb(0, 50, 170);
  padding: 6px;
  text-align: center;
  display: none;
  position: absolute;
}

.limited-hours {
  color: rgb(170, 50, 0);
}

.limited-hours:hover + .limited-hours-tooltip {
  display: block;
}

.limited-hours-tooltip {
  border: rgb(170, 50, 0) solid 1px;
  background-color: lightcoral;
  color: rgb(170, 50, 0);
  padding: 6px;
  text-align: center;
  display: none;
  position: absolute;
}

footer  {
  width: 100%;
}

div.footer {
  width: 100%;
  text-align: center;
  background: white;
}

@media (max-width: 499px) {
  #root {
    min-height: calc(100vh - 75px);
  }

  footer  {
    height: 75px;
  }

  div.footer {
    height: 75px;
  }

  .map-container iframe {
    display: none;
  }

  .monthly-parking, .short-term-parking, .faq-row {
    flex-flow: column;
  }
}

@media (min-width: 500px) {
  #root {
    min-height: calc(100vh - 25px);
  }

  footer  {
    height: 25px;
  }

  div.footer {
    height: 25px;
  }
}
